<template>
  <div>
    <v-simple-table
      fixed-header
      height="calc(100vh - 145px)"
      class="table-padding-2"
    >
      <template v-slot:default>
        <thead class="v-data-table-header">
          <tr>
            <th role="columnheader">
              <InputFilter
                :label="$t('labels.id')"
                :placeholder="$t('labels.id')"
                name="id_identity"
                sort-name="id_identity"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader">
              <InputFilter
                :label="$t('labels.id_employee')"
                :placeholder="$t('labels.id_employee')"
                name="id"
                sort-name="id"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader">
              <DateRangeFilter
                :label="$t('labels.date_create')"
                :placeholder="$t('labels.date_create')"
                name="created_at"
                sort-name="created_at"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader">
              <InputFilter
                :label="$t('labels.name')"
                :placeholder="$t('labels.name')"
                name="full_name"
                sort-name="full_name"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader">
              <InputFilter
                :label="$t('labels.account')"
                :placeholder="$t('labels.account')"
                name="email"
                sort-name="email"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader">
              <SelectWarehouse
                :label="$t('labels.warehouse')"
                :placeholder="$t('labels.warehouse')"
                name="id_warehouse"
                sort-name="warehouse_code"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader">
              <SelectFilter
                :options="departments"
                :label="$t('labels.department')"
                :placeholder="$t('labels.department')"
                name="id_department"
                sort-name="department"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader">
              <SelectFilter
                :options="positions"
                :label="$t('labels.staff_position')"
                :placeholder="$t('labels.staff_position')"
                name="id_position"
                sort-name="position"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader">
              <SelectFilter
                :options="statusOptions"
                :label="$t('labels.status')"
                :placeholder="$t('labels.status')"
                name="status"
                sort-name="status"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="employee in items" :key="employee.id" class="text-center">
            <td>{{ employee.id_identity }}</td>
            <td>{{ employee.id }}</td>
            <td>{{ formatDateTime(employee.created_at) }}</td>
            <td>
              <span
                class="cursor-pointer text-decoration-underline blue--text"
                v-if="checkPermission(['employee_update_info'])"
                @click="showDetailDialog(employee)"
              >
                {{ employee.full_name }}</span
              >
              <span v-else>{{ employee.full_name }}</span>
            </td>
            <td>{{ employee.email }}</td>
            <td>{{ employee.employee_warehouse_codes }}</td>
            <td>
              {{
                $i18n.locale === "vi"
                  ? employee.department
                  : employee.department_en
              }}
            </td>
            <td>
              {{
                $i18n.locale === "vi" ? employee.position : employee.position_en
              }}
            </td>
            <td>
              <v-switch
                v-if="checkPermission(['employee_update_info'])"
                class="switch-center"
                v-model="employee.status"
                @change="switchEmployeeStatus(employee)"
                :key="`employee_${employee.id}_${employee.status}`"
              ></v-switch>
              <span v-else>
                {{ employee.status ? "Yes" : "No" }}
              </span>
            </td>
            <td>
              <v-btn
                color="warning"
                v-if="checkPermission(['super_admin'])"
                :disabled="!employee.status"
                outlined
                x-small
                block
                class="mb-1"
                @click="loginWith(employee)"
              >
                Login
              </v-btn>

              <v-btn
                color="success"
                v-if="
                  checkPermission([
                    'warehouse_admin_permission',
                    'warehouse_admin_permission_all',
                  ])
                "
                outlined
                x-small
                block
                @click="showPermissionDialog(employee)"
              >
                {{ $t("labels.set_permission") }}
              </v-btn>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <v-row class="pt-3">
      <v-col cols="12" sm="3">
        <div class="d-flex table-footer-count mt-3">
          <div class="mr-2">
            {{ $t("labels.employee") }}: <b>{{ formatNumber(totalItem) }}</b>
          </div>
          <div class="mr-2">
            Active: <b>{{ formatNumber(totalActive) }}</b>
          </div>
        </div>
      </v-col>
      <v-col cols="12" sm="6">
        <v-pagination
          v-model="page"
          :length="totalPage"
          :total-visible="6"
        ></v-pagination>
      </v-col>
      <v-col cols="12" sm="3"></v-col>
    </v-row>

    <v-dialog v-model="detailDialog" persistent max-width="640px">
      <Detail
        v-if="detailDialog && employee && employee.id"
        :employee="employee"
        :departments="departments"
        :positions="positions"
        @closeDialog="hideDetailDialog"
        @refreshData="getList"
      />
    </v-dialog>

    <v-dialog v-model="permissionDialog" max-width="1200px">
      <Permission
        v-if="permissionDialog && employee && employee.id"
        :employee="employee"
        @closeDialog="hidePermissionDialog"
        @refreshData="getList"
      />
    </v-dialog>
  </div>
</template>

<script>
import { debounce, formatDateTime, formatNumber } from "@/libs/helpers";
import { httpClient } from "@/libs/http";

export default {
  components: {
    SelectWarehouse: () => import("@/components/table/SelectWarehouse"),
    DateRangeFilter: () => import("@/components/table/DateRangeFilter"),
    SelectFilter: () => import("@/components/table/SelectFilter"),
    InputFilter: () => import("@/components/table/InputFilter"),
    Detail: () => import("@/components/employee/Detail"),
    Permission: () => import("@/components/employee/Permission"),
  },
  name: "Index",
  data: () => ({
    page: 1,
    totalPage: 1,
    totalItem: 0,
    totalActive: 0,
    items: [],
    filters: {
      sort_by: "id",
      sort_type: "desc",
    },
    statusOptions: [
      {
        text: "Active",
        value: 10,
      },
      {
        text: "Inactive",
        value: 0,
      },
    ],
    employee: {},
    detailDialog: false,
    permissionDialog: false,
    isLoading: false,
    departments: [],
    positions: [],
  }),
  watch: {
    page() {
      this.getList();
    },
    filters: {
      handler() {
        this.page = 1;
        this.getList();
      },
      deep: true,
    },
  },
  methods: {
    formatDateTime,
    formatNumber,
    getList: debounce(function () {
      httpClient
        .post("/employee/v1/list", { ...this.filters, page: this.page })
        .then(({ data }) => {
          this.totalPage = data.totalPage;
          this.totalItem = data.total;
          this.totalActive = data.total_active;
          this.items = [...data.rows].map((r) => {
            r.status = r.identity_status === 10;
            return r;
          });
        });
    }, 500),
    onFilterChange(filter) {
      this.filters = { ...this.filters, [filter.name]: filter.value };
    },
    onSortChange(sort) {
      this.filters = { ...this.filters, ...sort };
    },
    switchEmployeeStatus(employee) {
      if (
        !confirm(
          this.$t("messages.change_empl_status", {
            employee: employee.full_name,
          })
        )
      ) {
        this.getList();
        return false;
      }
      httpClient
        .post("/employee/v1/update-status", {
          identity_id: employee.id_identity,
          status: employee.status ? 10 : 0,
        })
        .then(({ data }) => {
          console.log("switchEmployeeStatus", data);
        });
    },
    showDetailDialog(employee) {
      this.employee = { ...employee };
      this.detailDialog = true;
    },
    hideDetailDialog() {
      this.employee = {};
      this.detailDialog = false;
    },
    showPermissionDialog(employee) {
      this.employee = { ...employee };
      this.permissionDialog = true;
    },
    hidePermissionDialog() {
      this.employee = {};
      this.permissionDialog = false;
    },
    async getDepartmentAndPosition() {
      const { data } = await httpClient.post(
        "/employee/v1/list-department-position"
      );
      this.departments = [...data.departments].map((e) => ({
        value: e.id,
        text: this.$i18n.locale === "vi" ? e.department : e.department_en,
      }));
      this.positions = [...data.positions].map((e) => ({
        value: e.id,
        text: this.$i18n.locale === "vi" ? e.position : e.position_en,
      }));
    },
    async loginWith(employee) {
      if (!confirm(`${this.$t("labels.login_with")}: ${employee.full_name}`)) {
        return false;
      }

      if (this.isLoading) {
        this.isLoading = false;
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        const { data } = await httpClient.post("/employee/v1/login-with", {
          employee,
        });
        this.isLoading = false;

        const st = localStorage.getItem("_st");
        localStorage.setItem("_sto", st);
        localStorage.setItem("_st", data.token);

        localStorage.setItem(
          "_wh",
          (data.warehouses[0] && data.warehouses[0].id) || 0
        );

        window.location = process.env.VUE_APP_V2_HOME_PATH;
      } catch (e) {
        this.isLoading = false;
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
      }
    },
  },
  mounted() {
    this.getList();
    this.getDepartmentAndPosition();
  },
};
</script>

<style scoped></style>
